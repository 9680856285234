import { InteractionConfig } from './types';
import { AnimatePresence, motion } from 'framer-motion';

export default function Interaction(props: {
	shouldPlay: boolean;
	config: InteractionConfig;
	onLoaded: () => void;
	onDone: () => void;
	onError: (e: Error) => void;
}) {
	// call after preloading is done
	props.onLoaded();
	// call onDone when your interaction is done or after some timeout
	setTimeout(() => {
		props.onDone();
	}, props.config.timeout);

	return (
		<AnimatePresence>
			<div className="relative w-full h-full">
				<audio src="/eldenringheal.mp3" autoPlay={true} />
				<motion.div

					initial={{ opacity: 0, y: 50 }}
					exit={{ opacity: 0, y: 50 }}
					animate={{ opacity: 1, y: 0 }}
					transition={{ duration: 0.2, delay: 0 }}
				>
					<div>
						<img alt="error" src="/eldenringmessage.png" className="w-[800px] max-w-none" />
						<div className='absolute left-[80px] top-2'>
								<div className='p-2 text-[#F8F9F3] min-w-[200px] max-w-[200px]'>{props.config.msgFrom}</div>
						</div>
						<div className='absolute left-[140px] top-8'>
								<div className='font-bold text-xl p-2 text-[#F8F9F3] min-w-[200px] max-w-[400px]'>{props.config.msg}</div>
						</div>
					</div>
				</motion.div>
			</div>
		</AnimatePresence>
	);
}

